body {
  margin: 0;
  text-align: center;
  font-size: 1.4em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  width: 100%;
  background-color: #e0ffff;
  background: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.6)),url('./app/images/clouds01.jpg') no-repeat;
  background-size: cover;
  will-change: transform;
}
header {
  width: 100%;
  height: 100px;
  background: #ffffff url('./app/images/clouds01.jpg') no-repeat;
  background-size: cover;
}

.apodTitle {
  color:#00008b;
}

